import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Contato from '../componentes/Contato';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { id_firebase } from '../content-site';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function VeiculosRecentes(data) {
    const veiculos = data.veiculos;
    const listVeiculos = veiculos.map((v) =>
        <div className="item" key={v.id}>
            <div className="car-item text-center" >
                <Link to={"/veiculo/" + v.id}>
                <div className="car-image">
                    {
                        v.imagens.length > 0 ?
                            v.imagens[0].fileThumb !== undefined ?
                                <img className="img-fluid" src={v.imagens[0].fileThumb} alt=""></img>
                                :
                                <img className="img-fluid" src={v.imagens[0].fileUrl} alt=""></img>
                            : <img className="img-fluid" src="/img/car_default.png" alt='imagem veículo'></img>
                    }
                    <div className="car-overlay-banner"></div>
                </div>
                <div className="car-content">
                    <Link to={"/veiculo/" + v.id}>{v.vefipe_marca} {v.vefipe_name}</Link>
                    <div className="separator"></div>
                    <div className="price">
                        <span className="new-price">{formatMoney(v.vevalor)}</span>
                    </div>
                </div>
                </Link>
            </div>
        </div>
    );

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        navText: ["Prev", "Next"],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
    
            }
        },
    };

    return <OwlCarousel className='owl-theme'  {...options} nav>{listVeiculos}</OwlCarousel>

}


function ImagensLoja(data) {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    const imagens = data.images;
    const listImagens = imagens.map((key) =>
        <div key={key}><img className="img-responsive center-block" src={key} alt={"Banner " + data.revenda.penome_fantasia} /></div>
    );
    return <Slider {...settings}>{listImagens}</Slider>;

}

class Inicio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    items.push(document);
                });
                if (this.mounted) {
                    this.setState({
                        instagramData: items
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                        items.push(document.imagem_url);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        bannerData: items
                    });
                }
            });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
        } = this.state;

        if (this.state.revenda.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <div
                    id="rev_slider_3_1_wrapper"
                    className="rev_slider_wrapper fullwidthbanner-container rev_slider_3_1_wrapper"
                    data-alias="car-dealer-05"
                    data-source="gallery"
                    >

                    <div
                        id="rev_slider_3_1"
                        className="rev_slider fullwidthabanner"
                        style={{ display: "none" }}
                        data-version="5.3.0.2"
                    >
                        <ul>

                        <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img01.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img01.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img02.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img02.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img03.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img03.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            {/* <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img04.png"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img04.png"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img05.png"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img05.png"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li> */}

                        </ul>
                        <div
                            className="tp-bannertimer tp-bottom"
                            style={{ visibility: "hidden !important" }}
                        ></div>
                    </div>
                </div>

                <section className="welcome-block objects-car page-section-ptb white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                            <div className="section-title">
                                    <span>Bem-vindo ao nosso site </span>
                                    <h2>Pablo Teles <br/> consultor de vendas <br/> Auto Motors Uberlândia - MG, </h2>
                                    <p>"Pablo é um especialista em ajudar seus clientes a encontrar o veículo ideal que atenda a todas as suas necessidades. Como Consultor de Vendas na Auto Motors Uberlândia - MG, você pode esperar um atendimento excepcional e personalizado."</p>
                                    <div className="separator"></div>
                                    <p>
                                        <i class="fa fa-rocket icon mb16 color-default" data-icon="true" style={{color:'#CC0000', fontSize:"50px"}}></i>
                                        <span>Missão</span>
                                        Atender os clientes com eficiência, honestidade, profissionalismo e competência 
                                        oferecendo produtos e serviços de qualidade e preço justo. Além de garantir 
                                        crescimento para a empresa, acionistas, colaboradores, parceiros e sociedade.
                                    </p> 
                                    <p> 
                                        <i class="fa fa-eye icon mb16 color-default" data-icon="true" style={{color:'#CC0000', fontSize:"50px"}}></i>
                                        <span>Visão</span>
                                        Consolidar-se como referência no segmento automotivo, oferecendo ao mercado 
                                        produtos e serviços de qualidade com excelência no atendimento. E, ser a melhor, 
                                        mais moderna e conhecida pelos clientes internos, externos e sociedade.
                                    </p>
                                    <p>
                                        <i class="fa fa-group icon mb16 color-default" data-icon="true" style={{color:'#CC0000', fontSize:"50px"}}></i>
                                        <span>Valores</span>
                                        Respeito à pessoa, seja ela cliente, colaborador ou fornecedor. Somos uma empresa 
                                        íntegra, honesta, ética e altamente profissional. Em nossa conduta vencemos os 
                                        desafios em clima de cooperação, trabalho em equipe, promovendo um ambiente de 
                                        motivação e aprendizagem permanente. Somos empreendedores, inovadores e temos a 
                                        excelência como meta.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="feature-car bg-2 bg-overlay-black-70 page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h2 className="text-white">Veículos recentes</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <VeiculosRecentes veiculos={veiculos} />
                            </div>
                        </div>
                    </div>
                </section>
                    
                <section className="financiamento-section"> 
                    <div class="MuiBox-root jss1182 jss1133">
                        <div class="jss1132"></div>
                        <div class="MuiBox-root jss1183 jss1130">
                            <h2 class="secondary-label jss1129">Precisa de um financiamento?<br/>
                            <strong>Aprove sua ficha de financiamento conosco.</strong></h2>
                            <div className="MuiBox-root jss1184">
                                <a className="jss653 jss1139 jss1131" target="_blank" href="index.html#/financiamento">
                                    <button className="MuiButtonBase-root MuiButton-root MuiButton-contained jss668" tabindex="0" type="button">
                                        <span className="MuiButton-label jss656">Simule seu financiamento</span>
                                        <span className="MuiTouchRipple-root"></span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <Contato revenda={this.state.revenda} />

                <hr className="gray"></hr>

                <Rodape revenda={this.state.revenda} />
            </div>
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Inicio